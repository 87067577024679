'use strict'

import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/**
 * Translate b2c link structure to our internal urls
 * @param {LinkStructure} linkStruct
 * @return {string}
 */
export function useUrlFromLink(linkStruct) {
    const {buildUrl} = useMultiSite()

    switch (linkStruct?.type) {
        case 'CATEGORY':
            return buildUrl(`/category/${linkStruct.target}`)
        case 'SEARCH':
            return buildUrl(`/search?q=${linkStruct.target}`)
        case 'PRODUCT':
            return buildUrl(`/product/${linkStruct.target}`)
        case 'PAGE':
            return buildUrl(`/page/${linkStruct.target}`)
        case 'EXTERNAL':
            return linkStruct.target
    }
    return null
}
