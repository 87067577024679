import React, {useId} from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {Box, Text, useBreakpointValue} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import Carousel from '../../../components/carousel'

/**
 * PDC Home Home Services Push component
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Title font size
 * @param {string} titleFontColor - Title font color
 * @param {string} desc - Description
 * @param {string} backgroundColor - Background color
 * @param {string} textColor - Text color
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Home Home Services Push component
 */
export const PDCHomeServicesPush = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    desc,
    backgroundColor,
    textColor,
    smallNbSlidesToShow,
    regions
}) => {
    const intl = useIntl()
    let tiles = []
    let link = null

    // Get the tiles and link components from the regions
    if (regions) {
        regions.forEach((region) => {
            if (region.id === 'pdc_home_services_push_tiles') {
                tiles = region.components
            } else if (
                region.id === 'pdc_home_services_push_links' &&
                region.components &&
                region.components.length > 0
            ) {
                link = region.components[0]
            }
        })
    }

    const titleId = useId()
    const isMobile = useBreakpointValue({base: true, md: false})
    const carouselSettings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: smallNbSlidesToShow || 1,
        centerMode: true,
        centerPadding: '40px'
    }

    const tilesComponents = tiles.map((tile, index) => (
        <Component key={tile?.id || index} component={tile} />
    ))

    return (
        <Box
            as="section"
            aria-describedby={titleId}
            backgroundColor={backgroundColor}
            color={textColor}
            pb="64px"
        >
            <Box
                maxWidth="594px"
                flex={1}
                mt={['80px', null, '64px']}
                mb={['64px', null, '64px']}
                mx={['44px', null, 'auto']}
            >
                <PDCTitle
                    width={'100%'}
                    title={title}
                    titleFontColor={titleFontColor}
                    titleHeadingLevel={titleHeadingLevel}
                    textColor={textColor}
                    txtHorizontalPos={desc ? 'left' : 'center'}
                    id={titleId}
                    pb={['6px', null, '16px']}
                />

                {desc && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                        variant="bodyLarge2"
                        pt={['18px', null, '8px']}
                    />
                )}
            </Box>
            {/* Display a carousel only on mobile and if there are more tiles than the number of slides to show */}
            {isMobile && tilesComponents && tilesComponents.length > smallNbSlidesToShow ? (
                <Carousel
                    settings={carouselSettings}
                    label={intl.formatMessage({
                        id: 'home.services.push.carousel.label',
                        defaultMessage: 'Home services push carousel'
                    })}
                >
                    {tilesComponents}
                </Carousel>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    maxWidth={['none', null, '75%']}
                    mx="auto"
                    sx={{
                        '& > *': {
                            flex: 1
                        }
                    }}
                >
                    {tilesComponents}
                </Box>
            )}
            {link && (
                <Box pt={['70px', null, '80px']} align="center">
                    <Component component={link} />
                </Box>
            )}
        </Box>
    )
}

PDCHomeServicesPush.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    desc: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    smallNbSlidesToShow: PropTypes.number,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
