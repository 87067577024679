import React from 'react'
import PropTypes from 'prop-types'
import {Flex, LinkBox, LinkOverlay, useBreakpointValue} from '@chakra-ui/react'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'

/**
 * PDCHomeCategoriesPushTile component.
 * @typedef {Object} PDCHomeCategoriesPushTileProps
 * @property {string} title - Title of the tile.
 * @property {string} desc - Description of the tile.
 * @property {string} link - Link of the tile.
 * @property {string} linkLabel - Label of the link.
 * @property {object} smallImage - Small image object.
 * @property {object} mediumImage - Medium image object.
 * @property {object} largeImage - Large image object.
 * @property {object} xlargeImage - Extra large image object.
 * @property {object} xxlargeImage - Extra extra large image object.
 * @property {string} imageTitle - Title of the image.
 * @property {string} imageAlt - Alt of the image.
 * @returns {React.ReactElement} - PDCHomeCategoriesPushTile component.
 */
export const PDCHomeCategoriesPushTile = ({
    category,
    title,
    titleHeadingLevel,
    titleFontColor,
    txtVerticalPos,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt
}) => {
    // Primary URL used to wrap the component.
    const categoryUrl = categoryUrlBuilder({id: category})

    const txtPosFlex = {
        'top left': 'flex-start',
        middle: 'center',
        'bottom left': 'flex-end'
    }

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    return (
        <LinkBox as="article" textAlign="center" textDecoration="none" height={'100%'}>
            <DynamicImage
                src={`${currentImage?.url}[?sw={width}]`}
                widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                position="relative"
                height={'100%'}
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: imageAlt === 'empty' ? '' : imageAlt || title,
                    title: imageTitle === 'empty' ? '' : imageTitle || title
                }}
            />
            <Flex
                alignItems="flex-start"
                direction={'column'}
                justifyContent={txtPosFlex[txtVerticalPos]}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                p={[
                    `10px 10px ${txtVerticalPos === 'bottom left' ? '86px' : '10px'}`,
                    null,
                    `20px 20px ${txtVerticalPos === 'bottom left' ? '96px' : '20px'}`
                ]}
            >
                <LinkOverlay href={categoryUrl}>
                    <PDCTitle
                        title={title}
                        titleFontColor={titleFontColor}
                        titleHeadingLevel={titleHeadingLevel}
                        textColor={'white'}
                    />
                </LinkOverlay>
            </Flex>
        </LinkBox>
    )
}

PDCHomeCategoriesPushTile.propTypes = {
    category: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    txtVerticalPos: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string
}
