import {useMemo} from 'react'

/**
 * Used for the page designer components, returns element based on breakpoint.
 * If not set, we take the closest element from the previous breakpoints
 * @param {Object} elements An object with the elements for each breakpoint.
 * @param {Object} extraConditions An object with the extra conditions for each breakpoint.
 * @param {Object} extraConditions.conditionMd Condition for medium breakpoint.
 * @param {Object} extraConditions.conditionLg Condition for large breakpoint.
 * @param {Object} extraConditions.conditionXl Condition for xlarge breakpoint.
 * @param {Object} extraConditions.condition2xl Condition for xxlarge breakpoint.
 * @returns {Object} An object with the appropriate element for each breakpoint.
 */
const usePDCResponsiveElement = ({
    smallEl,
    mediumEl,
    largeEl,
    xlargeEl,
    xxlargeEl,
    extraConditions = {}
}) => {
    const {
        conditionMd = true,
        conditionLg = true,
        conditionXl = true,
        condition2xl = true
    } = extraConditions
    const getElement = (condition, el, fallback) => (condition && el !== undefined ? el : fallback)

    return useMemo(() => ({
        base: smallEl,
        sm: smallEl,
        md: getElement(conditionMd, mediumEl, smallEl),
        lg: getElement(conditionLg, largeEl, getElement(conditionMd, mediumEl, smallEl)),
        xl: getElement(
            conditionXl,
            xlargeEl,
            getElement(conditionLg, largeEl, getElement(conditionMd, mediumEl, smallEl))
        ),
        '2xl': getElement(
            condition2xl,
            xxlargeEl,
            getElement(
                conditionXl,
                xlargeEl,
                getElement(conditionLg, largeEl, getElement(conditionMd, mediumEl, smallEl))
            )
        )
    }))
}

export default usePDCResponsiveElement
