import React, {useId} from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {Box, IconButton, useBreakpointValue} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import {ChevronRightIcon, ChevronLeftIcon} from '../../../components/custom-icons'
import {useIntl} from 'react-intl'
import Carousel from '../../../components/carousel'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'

/**
 * Next arrow for the carousel
 * @param {string} className - Class name
 * @param {object} style - Style
 * @param {function} onClick - On click function
 * @returns {JSX.Element} - Next arrow
 */
const NextArrow = ({className, style, onClick}) => {
    const intl = useIntl()

    return (
        <Box style={{...style}}>
            <IconButton
                aria-label={intl.formatMessage({
                    id: 'carousel.button.next',
                    defaultMessage: 'Next'
                })}
                icon={<ChevronRightIcon boxSize="12px" />}
                variant="ghost"
                onClick={onClick}
                className={className}
            />
        </Box>
    )
}

NextArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
}

/**
 * Previous arrow for the carousel
 * @param {string} className - Class name
 * @param {object} style - Style
 * @param {function} onClick - On click function
 * @returns {JSX.Element} - Previous arrow
 */
const PrevArrow = ({className, style, onClick}) => {
    const intl = useIntl()
    return (
        <Box style={{...style}}>
            <IconButton
                aria-label={intl.formatMessage({
                    id: 'carousel.button.prev',
                    defaultMessage: 'Previous'
                })}
                icon={<ChevronLeftIcon boxSize="12px" />}
                variant="ghost"
                onClick={onClick}
                className={className}
            />
        </Box>
    )
}

PrevArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
}

/**
 * PDC Home Home Categories Push component
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Title heading of the component
 * @param {number} smallNbSlidesToShow - Number of slides to show on small screens
 * @param {number} mediumNbSlidesToShow - Number of slides to show on medium screens
 * @param {number} largeNbSlidesToShow - Number of slides to show on large screens
 * @param {number} xlargeNbSlidesToShow - Number of slides to show on xlarge screens
 * @param {number} xxlargeNbSlidesToShow - Number of slides to show on xxlarge screens
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Home Home Categories Push component
 */
export const PDCHomeCategoriesPush = ({
    title,
    titleHeadingLevel,
    smallNbSlidesToShow,
    mediumNbSlidesToShow,
    largeNbSlidesToShow,
    xlargeNbSlidesToShow,
    xxlargeNbSlidesToShow,
    regions
}) => {
    const intl = useIntl()

    // Get the tiles from the regions
    let tiles = []
    if (regions) {
        tiles = regions[0].components || []
    }

    // Get the number of slides to show depending on the screen size
    const currentNbSlidesToShow = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallNbSlidesToShow || 1,
            mediumEl: mediumNbSlidesToShow || 2,
            largeEl: largeNbSlidesToShow,
            xlargeEl: xlargeNbSlidesToShow,
            xxlargeEl: xxlargeNbSlidesToShow
        })
    )

    const titleId = useId()
    const isMobile = useBreakpointValue({base: true, md: false})

    const carouselSettings = {
        dots: false,
        arrows: !isMobile,
        infinite: true,
        speed: 500,
        slidesToShow: currentNbSlidesToShow,
        centerMode: true,
        centerPadding: '10%',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    const tilesComponents = tiles.map((tile, index) => (
        <Component key={tile?.id || index} component={tile} />
    ))

    return (
        <Box as="section" aria-describedby={titleId} css={[PDCHomeCategoriesPush.css]}>
            <PDCTitle
                title={title}
                titleHeadingLevel={titleHeadingLevel}
                id={titleId}
                className={'sr-only'}
            />
            {/* Display a carousel only if there are more tiles than the number of slides to show */}
            {tilesComponents && tilesComponents.length > currentNbSlidesToShow ? (
                <Carousel
                    settings={carouselSettings}
                    label={intl.formatMessage({
                        id: 'home.categories.push.carousel.label',
                        defaultMessage: 'Home categories push carousel'
                    })}
                >
                    {tilesComponents}
                </Carousel>
            ) : (
                <Box display="flex" justifyContent="center">
                    {tilesComponents}
                </Box>
            )}
        </Box>
    )
}

PDCHomeCategoriesPush.css = css`
    overflow: hidden;

    .slick-slider {
        transform: translateX(-10%);
        width: 111%;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: inherit;
        & > div,
        .component,
        .container {
            height: 100%;
        }
    }
    .slick-arrow {
        position: absolute;
        left: auto;
        right: 86px;
        bottom: 40px;
        top: auto;
        width: 40px;
        height: 40px;
        background: white;
        z-index: 1;
        transform: none;
        color: var(--chakra-colors-fullBlack);
        border: 1px solid var(--chakra-colors-gray);
        &::before {
            content: '';
        }
    }
    .slick-next {
        right: 40px;
    }
`

PDCHomeCategoriesPush.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    smallNbSlidesToShow: PropTypes.number,
    mediumNbSlidesToShow: PropTypes.number,
    largeNbSlidesToShow: PropTypes.number,
    xlargeNbSlidesToShow: PropTypes.number,
    xxlargeNbSlidesToShow: PropTypes.number,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
