export default {
    baseStyle: {
        fontWeight: 'normal',
        lineHeight: '1'
    },
    sizes: {
        '6xl': {
            fontSize: '6xl',
            lineHeight: 1
        },
        '5xl': {
            fontSize: '5xl',
            lineHeight: 1
        },
        '4xl': {
            fontSize: '4xl',
            lineHeight: 1
        },
        '3xl': {
            fontSize: '3xl',
            lineHeight: 1
        },
        '2xl': {
            fontSize: '2xl',
            lineHeight: 1
        },
        xl: {
            fontSize: 'xl',
            lineHeight: 1
        }
    }
}
