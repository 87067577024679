const breakpoints = {
    base: '0em',
    sm: '320px',
    md: '768px',
    lg: '1200px',
    xl: '1440px',
    '2xl': '1920px'
}

export default breakpoints
