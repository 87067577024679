import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {Link, Flex} from '@chakra-ui/react'
import {useUrlFromLink} from '../../helpers/urlHelper'
import PDCTitle from '../../../components/pdc-title'

/**
 * @typedef {Object} PDCButtonProps
 * @property {string} url - URL to link to
 * @property {string} label - Label to display
 * @property {boolean} isExternal - Whether the link is external
 * @property {string} fontColor - Font color
 * @property {string} fontSize - Font size
 * @property {string} backgroundColor - Background color
 * @property {string} displayConfig - Display configuration (style of the link)
 * @returns {React.ReactElement} - PDCButton component.
 */
export const PDCButton = ({
    title,
    titleHeadingLevel,
    url,
    label,
    isExternal,
    fontColor,
    fontSize,
    displayConfig,
    backgroundColor,
    custom,
    ...props
}) => {
    const titleId = useId()
    const linkDisplayConfigVariant = {
        'transparent button': 'secondary',
        'inverted button': 'primary'
    }
    const variant = displayConfig ? linkDisplayConfigVariant[displayConfig] : ''

    const getColor = () => {
        return fontColor || (variant === 'primary' ? 'white' : 'fullBlack')
    }

    const getBorderColor = () => {
        if (variant === 'secondary' && fontColor) {
            return fontColor
        }
        return 'fullBlack'
    }

    const getBackgroundColor = () => {
        return backgroundColor || (variant === 'secondary' ? 'transparent' : 'fullBlack')
    }

    // We remove 'typeId' prop coming from the component itself to avoid react warning
    const propsToMerge = Object.fromEntries(
        Object.entries(props).filter((prop) => prop[0] !== 'typeId')
    )

    const updatedUrl = useUrlFromLink(custom?.url)

    // As this component is used in multiple contexts, we need to control the props from the parent
    // so we merge them with the default props and pass them to the link component
    const linkProps = {
        href: updatedUrl || url,
        isExternal,
        color: getColor(),
        borderColor: getBorderColor(),
        fontSize: fontSize || '12px',
        bg: getBackgroundColor(),
        variant: variant,
        ...propsToMerge
    }

    return (
        url && (
            <Flex
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                py={['35px', null, '40px']}
                aria-describedby={titleId}
            >
                <PDCTitle
                    title={title}
                    titleHeadingLevel={titleHeadingLevel}
                    id={titleId}
                    className={'sr-only'}
                />
                <Link {...linkProps} size={'md'}>
                    {label || url}
                </Link>
            </Flex>
        )
    )
}

PDCButton.propTypes = {
    title: PropTypes.string,
    titleHeadingLevel: PropTypes.string,
    url: PropTypes.string.isRequired,
    label: PropTypes.string,
    isExternal: PropTypes.bool,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    displayConfig: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    typeId: PropTypes.string,
    custom: PropTypes.shape({
        url: PropTypes.shape({
            target: PropTypes.string,
            value: PropTypes.string
        })
    })
}
