const textStyles = {
    eyeBrowLarge: {
        fontSize: 'xl',
        fontFamily: 'body',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '0.01em'
    },
    eyeBrowSmall: {
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: 500,
        lineHeight: 'shorter',
        letterSpacing: '0.01em'
    },
    bodyLarge1: {
        fontSize: 'lg',
        fontFamily: 'body',
        fontWeight: 500,
        lineHeight: 'short',
        letterSpacing: '0.01em'
    },
    bodyLarge2: {
        fontSize: 'lg',
        lineHeight: 'short',
        letterSpacing: '0.01em'
    },
    bodyBase1: {
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: 500,
        lineHeight: 'short',
        letterSpacing: '0.01em'
    },
    bodyBase2: {
        fontSize: 'md',
        lineHeight: 'short',
        letterSpacing: '0.01em'
    },
    bodySmall: {
        fontSize: 'sm',
        lineHeight: 'short',
        letterSpacing: '0.01em'
    },
    bodyXSmall: {
        fontSize: 'xs',
        lineHeight: 'short',
        letterSpacing: '0.04em'
    },
    descriptor: {
        fontSize: 'xs',
        fontFamily: 'body',
        fontWeight: 500,
        lineHeight: 'shorter',
        letterSpacing: '0.06em'
    },
    link: {
        fontSize: 'md',
        fontWeight: 500,
        lineHeight: 'shorter',
        letterSpacing: '0.01em',
        textDecoration: 'underline'
    }
}

export default textStyles
