import React from 'react'
import PropTypes from 'prop-types'
import {Alert as ChakraAlert} from '@salesforce/retail-react-app/app/components/shared/ui'
import {AlertIcon, AlertDescription, useToken} from '@chakra-ui/react'
import {Alert2Icon as CustomAlertIcon} from '../../../../components/custom-icons'
import {useStyleConfig} from '@salesforce/retail-react-app/app/components/shared/ui'

const Alert = ({status, description, customIcon = true}) => {
    const alertStyles = useStyleConfig('Alert', {variant: status})
    const [alertIconInsideColor] = useToken('colors', [alertStyles?.icon?.insideColor])

    return (
        <ChakraAlert status={status} variant={status}>
            {customIcon ? (
                <CustomAlertIcon {...alertStyles?.icon} insideColor={alertIconInsideColor} />
            ) : (
                <AlertIcon />
            )}
            <AlertDescription>{description}</AlertDescription>
        </ChakraAlert>
    )
}

Alert.propTypes = {
    status: PropTypes.oneOf(['info', 'warning', 'error']).isRequired,
    description: PropTypes.string.isRequired,
    customIcon: PropTypes.bool
}

export default Alert
