import React, {useId} from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import {Box, Flex, LinkBox, LinkOverlay, Text, useBreakpointValue} from '@chakra-ui/react'
import {PRODUCT_IMAGE_TYPE} from '../../../constants'
import PDCTitle from '../../../components/pdc-title'
import {ResponsiveBynderVideo} from '../../../components/responsive-bynder-video'
import Seo from '@salesforce/retail-react-app/app/components/seo'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import DisplayPrice from '../../../components/display-price'
import {useProduct} from '@salesforce/commerce-sdk-react'
import {filterImageGroups} from '@salesforce/retail-react-app/app/utils/product-utils'
import {productUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {getPriceData} from '@salesforce/retail-react-app/app/utils/product-utils'
import {useCurrency} from '@salesforce/retail-react-app/app/hooks'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'

/**
 * PDC Home Product Dyptich Video
 * @param {string} product - Product ID
 * @param {string} subTitle - Subtitle
 * @param {string} desc - Description
 * @param {string} linkLabel - Link label
 * @param {string} smallVideo - Video media ID for small breakpoint
 * @param {string} smallVideoStructuredData - Video structured data for small breakpoint
 * @param {boolean} smallVideoAutoplay - Autoplay for small breakpoint
 * @param {string} mediumVideo - Video media ID for medium breakpoint
 * @param {string} mediumVideoStructuredData - Video structured data for medium breakpoint
 * @param {boolean} mediumVideoAutoplay - Autoplay for medium breakpoint
 * @param {string} largeVideo - Video media ID for large breakpoint
 * @param {string} largeVideoStructuredData - Video structured data for large breakpoint
 * @param {boolean} largeVideoAutoplay - Autoplay for large breakpoint
 * @param {string} xlargeVideo - Video media ID for xlarge breakpoint
 * @param {string} xlargeVideoStructuredData - Video structured data for xlarge breakpoint
 * @param {boolean} xlargeVideoAutoplay - Autoplay for xlarge breakpoint
 * @param {string} xxlargeVideo - Video media ID for xxlarge breakpoint
 * @param {string} xxlargeVideoStructuredData - Video structured data for xxlarge breakpoint
 * @param {boolean} xxlargeVideoAutoplay - Autoplay for xxlarge breakpoint
 * @param {string} textColor - Text color
 * @param {string} backgroundColor - Background color
 * @param {string} videoPlacement - Video placement
 * @returns {JSX.Element} - PDC Home Product Dyptich Video component
 */
export const PDCHomeProductDyptichVideo = ({
    product,
    subTitle,
    desc,
    linkLabel,
    smallVideo,
    smallVideoStructuredData,
    smallVideoAutoplay,
    mediumVideo,
    mediumVideoStructuredData,
    mediumVideoAutoplay,
    largeVideo,
    largeVideoStructuredData,
    largeVideoAutoplay,
    xlargeVideo,
    xlargeVideoStructuredData,
    xlargeVideoAutoplay,
    xxlargeVideo,
    xxlargeVideoStructuredData,
    xxlargeVideoAutoplay,
    textColor,
    backgroundColor,
    videoPlacement
}) => {
    // Get the product data
    const {data: productData} = useProduct({
        parameters: {
            id: product,
            allImages: false
        }
    })
    // Get all image groups of the product.
    const filteredImageGroups = filterImageGroups(productData?.imageGroups, {
        viewType: PRODUCT_IMAGE_TYPE
    })

    // Return the first image of the first group.
    const image = filteredImageGroups?.[0]?.images[0]

    // Primary URL used to wrap the component.
    const productUrl = productUrlBuilder({id: productData?.id})

    // Get the right price for the product.
    const priceData = getPriceData(productData)

    // Get the currency
    const {currency} = useCurrency()

    // Choose video media ID based on breakpoint. If not set, we take the closest video media ID
    // from the previous breakpoints
    const videosPerBreakpoint = usePDCResponsiveElement({
        smallEl: smallVideo,
        mediumEl: mediumVideo,
        largeEl: largeVideo,
        xlargeEl: xlargeVideo,
        xxlargeEl: xxlargeVideo
    })

    // Choose video structured data based on breakpoint if the video media ID is set for the breakpoint
    const currentVideoStructuredData = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallVideoStructuredData,
            mediumEl: mediumVideoStructuredData,
            largeEl: largeVideoStructuredData,
            xlargeEl: xlargeVideoStructuredData,
            xxlargeEl: xxlargeVideoStructuredData,
            extraConditions: {
                conditionMd: mediumVideo,
                conditionLg: largeVideo,
                conditionXl: xlargeVideo,
                condition2xl: xxlargeVideo
            }
        })
    )
    // Choose autoplay based on breakpoint if the video media ID is set for the breakpoint
    const currentAutoplay = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallVideoAutoplay,
            mediumEl: mediumVideoAutoplay,
            largeEl: largeVideoAutoplay,
            xlargeEl: xlargeVideoAutoplay,
            xxlargeEl: xxlargeVideoAutoplay,
            extraConditions: {
                conditionMd: mediumVideo,
                conditionLg: largeVideo,
                conditionXl: xlargeVideo,
                condition2xl: xxlargeVideo
            }
        })
    )

    const titleId = useId()

    const videoBlock = (
        <Box width={['100%', '100%', '50%']} position="relative">
            <ResponsiveBynderVideo mediaIds={videosPerBreakpoint} autoplay={currentAutoplay} />
        </Box>
    )

    const txtBlock = productData && (
        <LinkBox
            display={'flex'}
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            width={['100%', null, '50%']}
            pt={['80px', null, '25px']}
            pb={['120px', null, '50px']}
            px={['10px', null, '12%']}
            color={textColor ? textColor : 'fullBlack'}
        >
            <Box>
                <DynamicImage
                    data-testid="pdc-home-product-dyptich-video-image"
                    src={`${image?.link}[?sw={width}]`}
                    display="flex"
                    justifyContent={'center'}
                    widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                    imageProps={{
                        width: ['40%', null, '75%'],
                        height: 'auto',
                        loading: 'auto',
                        alt: image?.alt,
                        title: image?.title
                    }}
                />

                <Flex
                    direction="column"
                    alignItems="center"
                    mt={['65px', null, null, '100px']}
                    flex={0}
                >
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: subTitle
                        }}
                        variant="descriptor"
                        textAlign={'center'}
                    />

                    <PDCTitle
                        width={['90%', null, '100%']}
                        title={productData?.name}
                        titleFontColor={textColor}
                        titleHeadingLevel={'h3'}
                        txtHorizontalPos={'center'}
                        id={titleId}
                        mt={'10px'}
                    />

                    <DisplayPrice
                        priceData={priceData}
                        currency={currency}
                        mt={'10px'}
                        textAlign={'center'}
                        display="flex"
                        currentPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                        listPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                    />
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                        variant="bodyLarge2"
                        textAlign={'center'}
                        mt={'16px'}
                        px={[null, null, null, '40px']}
                        color={textColor ? textColor : 'darkGray'}
                    />
                    <LinkOverlay href={productUrl}>
                        <Text variant="link" color={textColor} mt={'16px'} textAlign={'center'}>
                            {linkLabel}
                        </Text>
                    </LinkOverlay>
                </Flex>
            </Box>
        </LinkBox>
    )

    return (
        <Box
            css={[PDCHomeProductDyptichVideo.css]}
            as="section"
            aria-describedby={titleId}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
            backgroundColor={backgroundColor}
            color={textColor}
        >
            {currentVideoStructuredData && (
                <Seo>
                    <script type="application/ld+json">{currentVideoStructuredData}</script>
                </Seo>
            )}

            {videoPlacement === 'left (top)' ? (
                <>
                    {videoBlock}
                    {txtBlock}
                </>
            ) : (
                <>
                    {txtBlock}
                    {videoBlock}
                </>
            )}
        </Box>
    )
}

PDCHomeProductDyptichVideo.css = css`
    [data-bynder-widget],
    .bynder-widget {
        height: 100%;
    }
    video {
        object-fit: cover;
    }
`

PDCHomeProductDyptichVideo.propTypes = {
    product: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    linkLabel: PropTypes.string.isRequired,
    smallVideo: PropTypes.string.isRequired,
    smallVideoStructuredData: PropTypes.string,
    smallVideoAutoplay: PropTypes.bool,
    mediumVideo: PropTypes.string,
    mediumVideoStructuredData: PropTypes.string,
    mediumVideoAutoplay: PropTypes.bool,
    largeVideo: PropTypes.string,
    largeVideoStructuredData: PropTypes.string,
    largeVideoAutoplay: PropTypes.bool,
    xlargeVideo: PropTypes.string,
    xlargeVideoStructuredData: PropTypes.string,
    xlargeVideoAutoplay: PropTypes.bool,
    xxlargeVideo: PropTypes.string,
    xxlargeVideoStructuredData: PropTypes.string,
    xxlargeVideoAutoplay: PropTypes.bool,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    videoPlacement: PropTypes.string
}
