export default {
    baseStyle: {
        wrapper: {
            zIndex: 'overlay',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        circle: {
            position: 'absolute',
            width: '10%',
            height: 'auto',
            borderRadius: '50%',
            background: 'white'
        },
        spinner: {
            width: 'full',
            height: 'auto'
        }
    },
    parts: ['select']
}
