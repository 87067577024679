import theme from '@salesforce/retail-react-app/app/components/shared/theme'

export default {
    baseStyle: {
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            minWidth: '320px'
        },
        headerWrapper: {
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndices.sticky
        },
        hiddenLink: {
            fontSize: 'md',
            fontWeight: 400,
            lineHeight: 1.5,
            textDecoration: 'underline',
            borderRadius: 0,
            userSelect: 'none',
            border: '0',
            height: '1px',
            width: '1px',
            margin: '-1px',
            padding: '0',
            outline: '0',
            overflow: 'hidden',
            position: 'absolute',
            clip: 'rect(0 0 0 0)',
            _focus: {
                clip: 'auto',
                width: 'auto',
                height: 'auto',
                top: 4,
                left: 4,
                padding: 4,
                background: 'white',
                position: 'fixed'
            }
        }
    },
    parts: ['container']
}
