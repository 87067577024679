import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, Text} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'

/**
 * PDC About Text Block
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Title font size
 * @param {string} titleFontColor - Title font color
 * @param {string} subTitle - Subtitle
 * @param {string} desc - Description
 * @param {string} txtHorizontalPos - Text horizontal position
 * @param {string} width - Width of the text
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC About Text Block component
 */
export const PDCAboutTextBlock = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    desc,
    txtHorizontalPos,
    width,
    regions
}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const txtPosFlex = {
        left: 'flex-start',
        center: 'center'
    }

    const titleId = useId()
    const responsiveWidths =
        width === 'small'
            ? ['320px', null, '500px', '600px']
            : ['320px', null, '500px', '800px', '1000px']

    return (
        <Flex
            as="section"
            aria-describedby={titleId}
            justifyContent={'center'}
            alignItems={'center'}
            p={['80px 10px', null, '120px 20px']}
        >
            <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems={txtPosFlex[txtHorizontalPos]}
                width={responsiveWidths}
            >
                <Text
                    dangerouslySetInnerHTML={{
                        __html: subTitle
                    }}
                    variant="descriptor"
                    color={'fullBlack'}
                    mb={'20px'}
                />
                <PDCTitle
                    title={title}
                    titleFontColor={titleFontColor}
                    titleHeadingLevel={titleHeadingLevel}
                    txtHorizontalPos={txtHorizontalPos}
                    id={titleId}
                    mb={'20px'}
                />
                <Text
                    dangerouslySetInnerHTML={{
                        __html: desc
                    }}
                    variant="bodyLarge2"
                    textAlign={txtHorizontalPos}
                    color="darkGray"
                />

                {components && (
                    <Box pt={'20px'}>
                        {components.map((component, index) => {
                            component.txtHorizontalPos = txtHorizontalPos
                            return <Component key={component?.id || index} component={component} />
                        })}
                    </Box>
                )}
            </Flex>
        </Flex>
    )
}

PDCAboutTextBlock.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    txtHorizontalPos: PropTypes.string,
    width: PropTypes.string,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
