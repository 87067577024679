import React, {useId} from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {
    Divider,
    HStack,
    Flex,
    Text,
    LinkBox,
    LinkOverlay,
    useBreakpointValue
} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'

/**
 * @param {string} title - title of the component
 * @param {string} titleHeadingLevel - font size of the title
 * @param {string} titleFontColor - font color of the title
 * @param {string} subTitle - subtitle of the component
 * @param {string} linksBackgroundColor - background color of the links
 * @param {object} smallImage - small image
 * @param {object} mediumImage - medium image
 * @param {object} largeImage - large image
 * @param {object} xlargeImage - xlarge image
 * @param {object} xxlargeImage - xxlarge image
 * @param {string} imageTitle - image title
 * @param {string} imageAlt - image alt
 * @param {string} txtHorizontalPos - text horizontal position
 * @param {string} linksHorizontalPos - links horizontal position
 * @param {string} wholeComponentLink - whole component link
 * @param {object} regions - regions
 * @returns {JSX.Element} - PDCHomeFullScreenPushImage component
 */
export const PDCHomeFullScreenPushImage = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    linksBackgroundColor,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    txtHorizontalPos,
    linksHorizontalPos,
    wholeComponentLink,
    regions
}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const txtPosFlex = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    }

    const titleId = useId()

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    return (
        <LinkBox
            css={[PDCHomeFullScreenPushImage.css]}
            aria-describedby={titleId}
            as="section"
            position="relative"
        >
            <DynamicImage
                src={`${currentImage?.url}[?sw={width}]`}
                widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                position="relative"
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: imageAlt === 'empty' ? '' : imageAlt ? imageAlt : title,
                    title: imageTitle === 'empty' ? '' : imageTitle ? imageTitle : title
                }}
            />
            <Flex
                alignItems="center"
                justifyContent={txtPosFlex[txtHorizontalPos]}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                p={['32px 10px', null, '32px 20px']}
            >
                <Flex
                    direction={['column', null, 'row']}
                    justifyContent={['center', null, txtPosFlex[txtHorizontalPos]]}
                    alignItems={[txtPosFlex[txtHorizontalPos], null, 'center']}
                    grow={1}
                    shrink={1}
                >
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: subTitle
                        }}
                        variant="eyeBrowSmall"
                        color={'fullBlack'}
                        mb={['10px', null, 0]}
                        mr={[0, null, '10px']}
                    />
                    <LinkOverlay href={wholeComponentLink}>
                        <PDCTitle
                            title={title}
                            titleFontColor={titleFontColor}
                            titleHeadingLevel={titleHeadingLevel}
                            txtHorizontalPos={txtHorizontalPos}
                            id={titleId}
                        />
                    </LinkOverlay>
                </Flex>
            </Flex>
            {components && components.length > 0 && (
                <Flex
                    alignItems="center"
                    justifyContent={txtPosFlex[linksHorizontalPos]}
                    position="absolute"
                    bottom="32px"
                    width="100%"
                    px={['10px', null, '20px']}
                >
                    <HStack
                        bg={linksBackgroundColor || 'white'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        px={'4px'}
                        gap={0}
                    >
                        {components.map((component, index) => {
                            return (
                                <HStack key={component?.id || index}>
                                    <Component component={component} />
                                    {index !== components.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            color="gray"
                                            height="13px"
                                        />
                                    )}
                                </HStack>
                            )
                        })}
                    </HStack>
                </Flex>
            )}
        </LinkBox>
    )
}

PDCHomeFullScreenPushImage.css = css`
    .chakra-link {
        padding: 18px 20px;
        text-decoration: none;
    }
`

PDCHomeFullScreenPushImage.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    linksBackgroundColor: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    txtHorizontalPos: PropTypes.string.isRequired,
    linksHorizontalPos: PropTypes.string.isRequired,
    wholeComponentLink: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
