import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {HStack} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import {HeaderNavLinksContext} from '../../../components/header'
import {HEADER_TRANSPARENT_LIGHT_VARIANT} from '../../../components/header/constants'

/**
 * PDC Header Navigation Links
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Header Navigation Links component
 */
export const PDCHeaderNavLinks = ({regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }
    const variant = useContext(HeaderNavLinksContext)
    let textColor = 'fullBlack'
    if (variant === HEADER_TRANSPARENT_LIGHT_VARIANT) {
        textColor = 'white'
    }

    return (
        components && (
            <HStack spacing={6}>
                {components.map((component, index) => {
                    component.textColor = textColor
                    component.fontSize = component.fontSize || '10px'
                    component.letterSpacing = '0.06em'
                    component.textDecoration = 'none'
                    component.padding = '14px'
                    return <Component key={component?.id || index} component={component} />
                })}
            </HStack>
        )
    )
}

PDCHeaderNavLinks.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
