import React, {useRef, useId} from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import {Box, Text} from '@chakra-ui/react'
import {useIntl} from 'react-intl'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/**
 * Carousel component
 * @param {Object} settings Carousel settings
 * @param {Array} children Array of carousel children (tiles)
 * @param {Object} props Component props
 * @returns {React.ReactElement} Carousel component
 */
const Carousel = ({settings, children, label, ...props}) => {
    const intl = useIntl()
    const titleId = useId()
    const swiperRef = useRef()

    // Handle accessibility attributes on the carousel
    const handleAccessibility = () => {
        const slides = swiperRef?.current?.innerSlider?.list?.querySelectorAll('.slick-slide')

        if (slides) {
            slides.forEach((slide) => {
                // Set A11y attributes on slides
                const slideIndex = parseInt(slide?.dataset?.index)
                slide.setAttribute('role', 'group')
                if (slideIndex >= 0 && slideIndex < children.length) {
                    slide.setAttribute(
                        'aria-label',
                        intl.formatMessage(
                            {
                                id: 'carousel.slide.label',
                                defaultMessage: 'slide {slideNumber} out of {totalSlides}'
                            },
                            {
                                slideNumber: parseInt(slide?.dataset?.index) + 1,
                                totalSlides: children.length
                            }
                        )
                    )
                }
                // We have to make links of non visible slides non focusable
                // because there is a bug in react-slick library
                const slideLinks = slide?.querySelectorAll('button, a')
                if (slideLinks && slideLinks.length > 0) {
                    if (slide?.classList?.contains('slick-active')) {
                        slideLinks?.forEach((link) => {
                            link.tabIndex = 0
                        })
                    } else {
                        slideLinks?.forEach((link) => {
                            link.tabIndex = -1
                        })
                    }
                }
            })
        }
    }

    handleAccessibility()

    const carouselSettings = {
        ...settings,
        afterChange: () => {
            handleAccessibility()
        }
    }

    const sliderClass =
        settings?.slidesToShow > 1 && settings?.centerMode && !settings?.infinite
            ? 'slick-translate'
            : ''

    return (
        <Box
            css={[Carousel.css(settings?.slidesToShow)]}
            aria-describedby={titleId}
            role="region"
            aria-label={label}
        >
            {/* Title for screen readers */}
            <Text className="sr-only" id={titleId}>
                {intl.formatMessage({
                    id: 'carousel.description',
                    defaultMessage:
                        'This is a carousel, you can use it by navigating through the slides using the arrows or swipe gestures.'
                })}
            </Text>
            <Slider
                ref={swiperRef}
                {...carouselSettings}
                {...props}
                // Fix a bug in react-slick which displays a white space before the first slide
                // when centerMode and infinite are true and more than one slide to show
                className={sliderClass}
            >
                {children}
            </Slider>
        </Box>
    )
}

Carousel.css = (nbSlidesToShow) => css`
    .slick-translate .slick-track {
        left: calc(-100% / ${nbSlidesToShow});
    }
`

Carousel.propTypes = {
    settings: PropTypes.object,
    children: PropTypes.array,
    label: PropTypes.string
}

export default Carousel
