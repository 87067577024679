import React, {useEffect, useState, useRef} from 'react'
import {useBreakpointValue} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import {BYNDER_ACCOUNT_URL, BYNDER_SCRIPT_ID, BYNDER_SCRIPT_SRC} from './constants'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

export const ResponsiveBynderVideo = ({mediaIds, autoplay = false}) => {
    const {locale} = useMultiSite()
    const breakpoint = useBreakpointValue({sm: 'sm', md: 'md', lg: 'lg', xl: 'xl', '2xl': '2xl'})
    const [currentMediaId, setCurrentMediaId] = useState(mediaIds[breakpoint])
    const scriptContainerRef = useRef(null)

    useEffect(() => {
        setCurrentMediaId(mediaIds[breakpoint])
    }, [breakpoint, mediaIds])

    useEffect(() => {
        const initializeResponsiveBynderVideo = () => {
            let script = document.getElementById(BYNDER_SCRIPT_ID)

            if (!script) {
                // If the script does not exist, create it
                script = document.createElement('script')
                script.id = BYNDER_SCRIPT_ID
                script.src = BYNDER_SCRIPT_SRC
                scriptContainerRef.current.appendChild(script)
            }

            // Update the script attributes
            script.setAttribute('data-account-url', BYNDER_ACCOUNT_URL)
            script.setAttribute('data-language', locale.alias)
        }

        initializeResponsiveBynderVideo()

        return () => {
            // Optionally, you can clean up the script if needed
            const script = document.getElementById(BYNDER_SCRIPT_ID)
            if (script && scriptContainerRef.current.contains(script)) {
                scriptContainerRef.current.removeChild(script)
            }
        }
    }, [currentMediaId, autoplay, locale.alias])

    return (
        <>
            <div
                key={currentMediaId}
                data-bynder-widget="video-item"
                data-media-id={currentMediaId}
                data-autoplay={autoplay.toString()}
            />
            <div ref={scriptContainerRef}></div>
        </>
    )
}

// Define prop types for the component
ResponsiveBynderVideo.propTypes = {
    mediaIds: PropTypes.objectOf(PropTypes.string).isRequired, // Changed to expect an object
    autoplay: PropTypes.bool
}
