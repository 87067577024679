import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Flex,
    Link,
    LinkBox,
    LinkOverlay,
    Text,
    AspectRatio,
    useBreakpointValue
} from '@chakra-ui/react'
import {PRODUCT_IMAGE_TYPE} from '../../../constants'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import DisplayPrice from '../../../components/display-price'
import {useProduct} from '@salesforce/commerce-sdk-react'
import {filterImageGroups} from '@salesforce/retail-react-app/app/utils/product-utils'
import {productUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {getPriceData} from '@salesforce/retail-react-app/app/utils/product-utils'
import {useCurrency} from '@salesforce/retail-react-app/app/hooks'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'

/**
 * This component is used to display a product with a dyptich image.
 * @param {string} product - The product id.
 * @param {string} subTitle - The subtitle of the product.
 * @param {string} desc - The description of the product.
 * @param {string} linkLabel - The label of the link.
 * @param {object} smallImage - The small image.
 * @param {object} mediumImage - The medium image.
 * @param {object} largeImage - The large image.
 * @param {object} xlargeImage - The xlarge image.
 * @param {object} xxlargeImage - The xxlarge image.
 * @param {string} imageTitle - The title of the image.
 * @param {string} imageAlt - The alt of the image.
 * @param {string} imageLink - The link of the image.
 * @param {string} textColor - The color of the text.
 * @param {string} backgroundColor - The color of the background.
 * @param {string} imagePlacement - The placement of the image.
 * @returns {React.ReactElement} - The product with a dyptich image.
 */
export const PDCHomeProductDyptichImage = ({
    product,
    subTitle,
    desc,
    linkLabel,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    imageLink,
    textColor,
    backgroundColor,
    imagePlacement
}) => {
    // Get the product data
    const {data: productData} = useProduct({
        parameters: {
            id: product,
            allImages: false
        }
    })
    // Get all image groups of the product.
    const filteredImageGroups = filterImageGroups(productData?.imageGroups, {
        viewType: PRODUCT_IMAGE_TYPE
    })

    // Return the first image of the first group.
    const image = filteredImageGroups?.[0]?.images[0]

    // Primary URL used to wrap the component.
    const productUrl = productUrlBuilder({id: productData?.id})

    // Get the right price for the product.
    const priceData = getPriceData(productData)

    // Get the currency
    const {currency} = useCurrency()

    // Get the current ratio according to the breakpoint
    const currentRatio = useBreakpointValue({base: 207 / 275, md: 143 / 180})

    // Choose image based on breakpoint. If not set, we take the closest image from the previous breakpoints
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    const titleId = useId()

    const imgBlock = currentImage && (
        <Link href={imageLink} width={['100%', '100%', '50%']} position="relative">
            <AspectRatio ratio={currentRatio} height={'100%'}>
                <DynamicImage
                    src={`${currentImage.url}[?sw={width}]`}
                    widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                    imageProps={{
                        width: '100%',
                        height: '100%',
                        loading: 'auto',
                        fetchpriority: 'high',
                        objectFit: 'cover',
                        alt: imageAlt === 'empty' ? '' : imageAlt ? imageAlt : productData?.name,
                        title:
                            imageTitle === 'empty'
                                ? ''
                                : imageTitle
                                ? imageTitle
                                : productData?.name
                    }}
                />
            </AspectRatio>
        </Link>
    )

    const txtBlock = productData && (
        <LinkBox
            display={'flex'}
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            width={['100%', null, '50%']}
            pt={['80px', null, '25px']}
            pb={['120px', null, '50px']}
            px={['10px', null, '12%']}
            color={textColor ? textColor : 'fullBlack'}
        >
            <Box>
                <DynamicImage
                    data-testid="pdc-home-product-dyptich-video-image"
                    src={`${image?.link}[?sw={width}]`}
                    display="flex"
                    justifyContent={'center'}
                    widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                    imageProps={{
                        width: ['40%', null, '75%'],
                        height: 'auto',
                        loading: 'auto',
                        alt: image?.alt,
                        title: image?.title
                    }}
                />

                <Flex
                    direction="column"
                    alignItems="center"
                    mt={['65px', null, null, '100px']}
                    flex={0}
                >
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: subTitle
                        }}
                        variant="descriptor"
                        textAlign={'center'}
                    />

                    <PDCTitle
                        width={['90%', null, '100%']}
                        title={productData?.name}
                        titleFontColor={textColor}
                        titleHeadingLevel={'h3'}
                        txtHorizontalPos={'center'}
                        id={titleId}
                        mt={'10px'}
                    />

                    <DisplayPrice
                        priceData={priceData}
                        currency={currency}
                        mt={'10px'}
                        textAlign={'center'}
                        display="flex"
                        currentPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                        listPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                    />
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                        variant="bodyLarge2"
                        textAlign={'center'}
                        mt={'16px'}
                        px={[null, null, null, '40px']}
                        color={textColor ? textColor : 'darkGray'}
                    />
                    <LinkOverlay href={productUrl}>
                        <Text variant="link" color={textColor} mt={'16px'} textAlign={'center'}>
                            {linkLabel}
                        </Text>
                    </LinkOverlay>
                </Flex>
            </Box>
        </LinkBox>
    )

    return (
        <Box
            as="section"
            aria-describedby={titleId}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
            backgroundColor={backgroundColor}
            color={textColor}
        >
            {imagePlacement === 'left (top)' ? (
                <>
                    {imgBlock}
                    {txtBlock}
                </>
            ) : (
                <>
                    {txtBlock}
                    {imgBlock}
                </>
            )}
        </Box>
    )
}

PDCHomeProductDyptichImage.propTypes = {
    product: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    linkLabel: PropTypes.string.isRequired,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageLink: PropTypes.string.isRequired,
    imagePlacement: PropTypes.string.isRequired
}
