export default {
    variants: {
        eyeBrowLarge: {
            textStyle: 'eyeBrowLarge'
        },
        eyeBrowSmall: {
            textStyle: 'eyeBrowSmall'
        },
        bodyLarge1: {
            textStyle: 'bodyLarge1'
        },
        bodyLarge2: {
            textStyle: 'bodyLarge2'
        },
        bodyBase1: {
            textStyle: 'bodyBase1'
        },
        bodyBase2: {
            textStyle: 'bodyBase2'
        },
        bodySmall: {
            textStyle: 'bodySmall'
        },
        bodyXSmall: {
            textStyle: 'bodyXSmall'
        },
        descriptor: {
            textStyle: 'descriptor'
        },
        link: {
            textStyle: 'link'
        }
    }
}
