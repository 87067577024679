export default {
    baseStyle: {
        icon: {
            display: 'none'
        },
        text: {
            color: 'red',
            fontSize: '11px',
            mt: 8,
            textAlign: 'left'
        }
    }
}
