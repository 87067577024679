export default {
    baseStyle: {
        px: '18px',
        py: '8px',
        bg: 'lightBeige',
        '--popper-arrow-bg': 'colors.lightBeige',
        color: 'fullBlack',
        borderRadius: '3px',
        maxW: '300px',
        fontSize: 'xl',
        fontFamily: 'body',
        fontWeight: 500,
        boxShadow: 'none',
        filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))'
    }
}
